<template>
  <main>

    <center-title title="临时产品方案"></center-title>
    <router-link to="/selectmygoodslist" class="el-button whtie submit" 
      >产品浏览</router-link>

   
      <router-link to="/mygoodslist" class="el-button whtie submit"  style="margin-bottom: 2%;"
      >产品方案</router-link>
      <router-link to="/existinglist" class="el-button whtie submit"  style="margin-bottom: 2%;"
      >临时产品方案</router-link>

 
    <article class="bg-white">
   
      <div class="core condition">
     
     
        <div class="pro-main row-between">
       
          <div class="pro-list" style="width: 100%;">
         
          <make-storeList activityType="2" priceSize="20" height="320" :join="false" :goodsList="lists" @delPro="
            (e) => {
              delHistory([e.history_id]);
            }" facomment="comment" @recordsChange="recordsChange"></make-storeList>
           <make-pagination @resetList="resetList" :orderObject="orderObject" v-if="lists.length"></make-pagination>
      <el-empty description="暂无商品" class="mt30 flex1" v-else></el-empty>
          </div>
        </div>
      </div>
      
      <el-button type="primary" class="whtie submit" @click="addGoods()">添加临时产品</el-button>
      <el-button type="primary" class="whtie submit" @click="saveGoodsList('ruleForm')">制作产品方案</el-button>
    </article>
  </main>
</template>
<script>
import MakeBreadCrumb from "@/components/MakeBreadCrumb";
import MakeStoreList from "@/components/MakeStoreList/existing_list_index";
import CenterTitle from "../components/CenterTitle";
export default {
  name: "",
  props: {},
  components: {
    "make-breadcrumb": MakeBreadCrumb,
    "make-storeList": MakeStoreList,
    "center-title": CenterTitle,
  },
  data() {
    return {
     
      lists: [],
      page_no: 1,
  
      orderObject: {}
    };
  },
  computed: {},
  watch: {},
  methods: { 
    addGoods(){
   
 
    // window.location.href ="/selectmygoodslist?plan_id="+id;
    window.sessionStorage.setItem("plan_id", "");


    this.$router.push('/selectmygoodslist');



       // 直接调用$router.push 实现携带参数的跳转
  //  this.$router.push({ path: '/selectmygoodslist/${'+id+'}'  })
  },

   /**
     * @description 获取商品列表
     * @returns void
     */
     getExistingList() {
  
    
      this.$api.getExistingListApi({
        page_no: this.page_no,
        page_size: 20,
      })
        .then((res) => {

          this.lists = res.data.lists.map(el => {
          el.image = el.image
          el.id = el.id
          el.name = el.name
          el.min_price = el.min_price
          el.market_price = el.market_price
          return el
        })
        let { list, count, page_size: size, page_no: page } = res.data;
        this.orderObject = res.data;
        this.orderObject = {
          page,
          size,
          count,
          list,
        };
       
        });
    },
    resetList(e) {
      this.page_no = e;
      this.getExistingList()
    },  
    saveGoodsList(){

      this.$router.push('/savegoodslist');
        savegoodslist
      // this.$api
      //   .addGoodsListApi()
      //   .then(res => {
      //     if (res.code == 1) {
      //       this.$message({
      //         message: res.msg,
      //         type: "success",
      //       });
      //       this.getExistingList();
         
      //     }

      //   });
      //   this.getExistingList();
    },
    recordsChange(records) {
      this.getExistingList();
    },
  },
  created() {
   
    this.getExistingList();
  },
 
};
</script>
<style lang='scss' scoped>
main {
  .core {
    width: 1170px;
  }

  .hot {
    padding: 33px 0;

    .title {
      width: 46px;
      height: 150px;
      padding: 28px 14px 0;
    }

    .hot-list {
      width: 1113px;
      .hot-item {
        border: $-solid-border;
        width: 359px;
        height: 150px;
        margin-right: 18px;
        padding: 20px;
        align-items: normal;

        &:last-child {
          margin-right: 0;
        }

        .item-pro {
          width: 140px;
          height: 108px;
          flex-shrink: 0;
        }

        .item-text {
          align-items: normal;

          .text-title {
            line-height: 20px;
            height: 40px;
          }

          .cart-box {
            width: 90px;
            height: 32px;
            border: $-solid-cartborder;
            cursor: pointer;
          }
        }
      }
    }
  }

  .condition {
    padding-top: 20px;
    padding-bottom: 40px;

    .condition-box {
      padding: 0px 20px;
      border: $-solid-border;

      .condition-t {
        height: 54px;
        border-bottom: $-dashed-border;

        .title {
          margin-right: 35px;
        }

        .t-content {
          padding: 0 8px;
          height: 28px;
          border: $-solid-border;
        }
      }

      .condition-b {
        padding: 15px 0;
        align-items: flex-start;
        line-height: 1.8;

        .title {
          margin-right: 43px;
          flex-shrink: 0;
        }

        .brand-list {
          span {
            margin-right: 60px;
            cursor: pointer;

            &.active,
            &:hover {
              color: $-color-primary;
            }
          }
        }
      }
    }
  }
}

.pro-main {
  align-items: flex-start;

  .recommend-list {
    width: 177px;

    .recommend-item {
      border: $-solid-border;
      padding: 0 10px;

      .title {
        height: 45px;
        line-height: 45px;
        border-bottom: $-solid-border;
      }

      /deep/.list {
        margin: 0;
        padding: 0 0 10px;

        .store-item {
          box-shadow: none;

          &:hover {
            .item-title {
              color: $-color-primary;
            }
          }

          .item-title {
            margin-bottom: 0;
          }

          .item-pro {
            margin-bottom: 5px;
          }
        }
      }
    }
  }

  .pro-list {
    width: 980px;

    .pro-condition {
      height: 46px;
      background: #f3f5f8;
      border: $-solid-border;

      ul {
        li {
          padding: 0 15px;
          cursor: pointer;

          span {
            margin-right: 5px;
          }

          .rotate {
            transform: rotate(180deg);
          }

          &.active {
            color: $-color-primary;
          }

          &.price-box {
            .arrow {
              position: relative;
              top: 02.5px;
            }

            em {
              transform: rotate(90deg);
              line-height: 1;
              font-size: 14px;
              width: 10px;
              height: 8px;
              color: #9e9e9e;
              font-weight: bold;
              background-image: url("~@/assets/icon/icon-arrow1.png");
              background-size: contain;
              background-repeat: no-repeat;

              &.active {
                color: $-color-primary;
                background-image: url("~@/assets/icon/icon-arrow10.png");
              }

              &:first-child {
                transform: rotate(270deg);
                margin-bottom: 5px;
              }
            }
          }
        }
      }

      .input-box {
        height: 30px;
        padding-right: 33px;

        input {
          border: 1px solid #d8d8d8;
          border-right: none;
          background-color: $-color-white;
          padding: 0 15px;
          height: inherit;
          width: 137px;
        }

        button {
          width: 73px;
          height: 30px;
          color: $-color-white;
          cursor: pointer;
        }
      }
    }

    /deep/ .list {
      padding-bottom: 20px;

      .store-item {
        .item-pro {
          margin-bottom: 10px;
        }

        .item-title {
          margin-bottom: 5px;
        }

        .item-cart {
          margin-top: 10px;
        }
      }
    }
  }
}
</style>